<template>
  <div
    v-if="
      getUser.role.slug === 'super-administrateur' ||
      getUser.role.slug === 'rh' ||
      getUser.role.slug === 'assistante-administrative' ||
      getUser.role.slug === 'comptable'
    "
  >
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Filtrages</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="10"
            lg="10"
            class="mb-md-0 mb-2"
          >
            <b-row>
              <b-col
                cols="12"
                sm="6"
                md="6"
                lg="3"
              >
                <label for="employee">Employé</label>
                <v-select
                  v-model="payloadFilter.employee_id"
                  :options="employeeOptions"
                  class="invoice-filter-select"
                  label="name"
                  :reduce="(employee) => employee.id"
                >
                  <template v-slot:no-options>
                    <b-spinner
                      v-if="isEmployeesWithoutPaginationLoading"
                      style="width: 2.5rem; height: 2.5rem"
                      class="align-middle text-info"
                    />
                    <span
                      v-else
                      class="mt-1 font-medium-1"
                    >Aucun employé ne correspond</span>
                  </template>
                </v-select>
              </b-col>
              <b-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <label for="month">Mois</label>
                <v-select
                  v-model="payloadFilter.month_salary"
                  :options="monthOptions"
                  label="text"
                  class="invoice-filter-select"
                  :reduce="(item) => item.value"
                />
              </b-col>
              <b-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <label for="year">Année</label>
                <v-select
                  v-model="payloadFilter.year"
                  :options="yearOptions"
                  class="invoice-filter-select w-100 mr-1"
                  :reduce="(item) => item.value"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>
              </b-col>
              <b-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <label for="status">Statut</label>
                <v-select
                  v-model="payloadFilter.status"
                  :options="paymentStatusOptions"
                  class="invoice-filter-select"
                  :reduce="(item) => item.value"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>
              </b-col>
              <b-col
                cols="12"
                sm="6"
                md="4"
                class="mt-2"
                lg="3"
              >
                <label for="status">Salaire payé par le client</label>
                <v-select
                  v-model="payloadFilter.client_payed"
                  :options="ClientPaymentOptions"
                  class="invoice-filter-select"
                  :reduce="(item) => item.value"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="mt-2"
              >
                <b-button
                  variant="primary"
                  @click="applyFilterEmployeePayedUnPayedPaymentAction()"
                >
                  <div v-if="isFilterEmployeePayedUnPayedPayment">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>
                  <span
                    v-else
                    class="text-nowrap font-medium-1"
                  >Filtrer</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card title="Liste des employées payé et non payé">
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="6"
          
        >
          <b-button
            variant="primary"
            @click="reLoadDataPaymentHistoryDirectOrdersAction()"
          >
            <div v-if="isReLoadDataPaymentHistoryDirectOrders">
              <span> Chargement ... </span>
              <b-spinner small />
            </div>
            <span
              v-else
              class="text-nowrap font-medium-1"
            >Actualiser</span>
          </b-button>
        </b-col>

       
      </b-row>

      <vue-good-table
        :columns="columns"
        :rows="rows"
        :is-loading="isPaymentEmployeesLoading"
      >
        <template slot="emptystate">
          <div class="text-center">
            <span> {{ isPaymentEmployeesLoading ? "" : "Liste vide" }} </span>
          </div>
        </template>

        <template slot="loadingContent">
          <div class="text-center text-info">
            <b-spinner class="align-middle" /> &nbsp;
            <span class="align-middle">Chargement... </span>
          </div>
        </template>

        <template
          slot="table-row"
          slot-scope="props"
        >
          <div
            v-if="props.column.field === 'employee_full_name'"
            class="text-nowrap text-center align-middle"
          >
            <span class="d-block text-nowrap text-info font-small-5">{{
              props.row.employee_full_name
            }}</span>
          </div>

          <div
            v-else-if="props.column.field === 'month_salary'"
            class="text-nowrap text-center align-middle"
          >
            <span class="d-block text-nowrap text-info font-small-5">{{
              props.row.month_salary
            }}</span>
          </div>

          <div
            v-else-if="props.column.field === 'year'"
            class="text-nowrap text-center align-middle"
          >
            <span class="d-block text-nowrap text-info font-small-5">{{
              props.row.year
            }}</span>
          </div>

          <div
            v-else-if="props.column.field === 'employee_salary_amount'"
            class="text-nowrap text-center align-middle"
          >
            <span
              v-if="props.row.employee_salary_amount"
              class="d-block text-nowrap text-white font-small-5"
            >
              <span
                v-if="props.row.employee_salary_amount"
                class="text-success"
              >
                {{ props.row.employee_salary_amount }} XOF
              </span>
              <span
                v-else
                class="text-danger"
              > 0 XOF </span>
            </span>
            <span
              v-else
              class="d-block text-nowrap text-white font-small-5"
            >
              0 FCFA
            </span>
          </div>

          <div
            v-else-if="
              props.column.field === 'set_employee_received_his_salary'
            "
            class="align-middle text-center font-small-5"
          >
            <b-badge
              pill
              :variant="`text-${resolveEmployeeReceivedHisSalary(
                props.row.employee_received_his_salary
              )}`"
              :class="`text-capitalize font-medium-1 text-${resolveEmployeeReceivedHisSalary(
                props.row.employee_received_his_salary
              )}`"
            >
              {{ props.row.set_employee_received_his_salary }}
            </b-badge>
          </div>
          <div
            v-else-if="
              props.column.field === 'status'
            "
            class="align-middle text-center font-small-5"
          >
            <b-badge
              pill
              :variant="`text-${resolveClientPayedEmployeeSalary(
                props.row.status
              )}`"
              :class="`text-capitalize font-medium-1 text-${resolveClientPayedEmployeeSalary(
                props.row.status
              )}`"
            >
              {{ props.row.status == 0 ? "Non" : "Oui" }}
            </b-badge>
          </div>
          <!-- <div class="align-middle text-center" v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="20"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                  v-if="getUser.role.slug === 'super-administrateur'"
                  :id="`details${props.row.id}`"
                  @click="showEmployeeWalletDetails(props.row)"
                >
                  <feather-icon icon="EyeIcon" class="cursor-pointer mr-1" size="25" />
                  <span>Voir le portefeuille employé</span>
                </b-dropdown-item>
            </b-dropdown>
          </span>
        </div> -->
        </template>
      </vue-good-table>

      <div class="d-flex justify-content-between mt-2 flex-wrap">
        <b-button
          :disabled="metaData.prev_page_url == null"
          variant="outline-secondary"
          @click="
            applyLoadMoreDirectOrderPaymentHistoryAction(metaData.prev_page_url)
          "
        >
          <span
            class="text-white text-nowrap font-medium-2"
          >Page précédente</span>
        </b-button>

        <div>
          <span class="text-white font-medium-2">
            {{ metaData.current_page }} sur {{ metaData.last_page }} pages</span>
        </div>
        <div>
          <span class="text-white font-medium-2"> Total : {{ total }}</span>
        </div>
        <b-button
          :disabled="metaData.next_page_url == null"
          variant="outline-secondary"
          @click="
            applyLoadMoreDirectOrderPaymentHistoryAction(metaData.next_page_url)
          "
        >
          <span
            class="text-nowrap font-medium-1 text-white"
          >Page suivante</span>
        </b-button>
      </div>
    </b-card>
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BCardHeader,
  BCardBody,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  beforeRouteLeave(to, from, next) {
    this.applyGetDirectOrdersSalaryPayments()
    next()
    // called when the route that renders this component is about to be navigated away from.
    // As with `beforeRouteUpdate`, it has access to `this` component instance.
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
    flatPickr,
    VueGoodTable,
  },

  data() {
    return {
      montant_total: {
        total_mtn:'',
        total_flooz: ''
      },
      isTotalAmountEmployeesWalletsLoading: false,
      totalAmountEmployeesWalletsPayload: {
        month_salary: '',
        year: '',
      },
      payloadFilter: {
        month_salary: '',
        year: '',
        employee_id: '',
        status: '',
        type: '',
        client_payed: ""
      },
      paymentStatusOptions: [
        {
          label: 'Reçu',
          value: 1,
        },
        {
          label: 'Non Reçu',
          value: 0,
        },
      ],
      ClientPaymentOptions: [
        {
          label: 'Oui',
          value: 1,
        },
        {
          label: 'Non',
          value: 0,
        },
      ],
      isReLoadDataPaymentHistoryDirectOrders: false,
      isFilterEmployeePayedUnPayedPayment: false,
      rows: [],
      employeeOptions: [],
      monthOptions: [
        { value: 'janvier', text: 'Janvier' },
        { value: 'février', text: 'Février' },
        { value: 'mars', text: 'Mars' },
        { value: 'avril', text: 'Avril' },
        { value: 'mai', text: 'Mai' },
        { value: 'juin', text: 'Juin' },
        { value: 'juillet', text: 'Juillet' },
        { value: 'août', text: 'Août' },
        { value: 'septembre', text: 'Septembre' },
        { value: 'octobre', text: 'Octobre' },
        { value: 'novembre', text: 'Novembre' },
        { value: 'décembre', text: 'Décembre' },
      ],
      columns: [
        {
          label: 'Employé',
          field: 'employee_full_name',
        },
        {
          label: 'Mois',
          field: 'month_salary',
        },
        {
          label: 'Année',
          field: 'year',
        },
        {
          label: 'Salaire',
          field: 'employee_salary_amount',
        },
        {
          label: 'Reçu',
          field: 'set_employee_received_his_salary',
        },
        {
          label: 'Salaire payé par le client',
          field: 'status',
        }
      ],
      avatarText,
      totalSalariesAmount: 0,
    }
  },

  computed: {
    ...mapGetters('professionals', ['getEmployeesWithoutPagination']),
    ...mapGetters([
      'isPaymentEmployeesLoading',
      'isEmployeesWithoutPaginationLoading',
    ]),
    ...mapGetters('orders', ['getDirectOrdersPaymentEmployees']),
    ...mapGetters('auth', ['getUser']),

    metaData() {
      const meta = {
        prev_page_url: '',
        next_page_url: '',
        current_page: '',
        last_page: '',
        current_page_url: '',
      }
      if (this.getDirectOrdersPaymentEmployees != null) {
        meta.prev_page_url = this.getDirectOrdersPaymentEmployees.prev_page_url
        meta.next_page_url = this.getDirectOrdersPaymentEmployees.next_page_url
        meta.current_page = this.getDirectOrdersPaymentEmployees.current_page
        meta.last_page = this.getDirectOrdersPaymentEmployees.last_page
        meta.current_page_url = `${this.getDirectOrdersPaymentEmployees.path}?page=${this.getDirectOrdersPaymentEmployees.current_page}`
        this.total = this.getDirectOrdersPaymentEmployees.total
      }
      return meta
    },

    yearOptions() {
      const data = []
      for (
        let i = 2021;
        i <= Number(new Date().getFullYear().toString());
        i++
      ) {
        data.push({
          label: i.toString(),
          value: i.toString(),
        })
      }
      return data
    },
  },

  watch: {
    isPaymentEmployeesLoading(val) {
      if (val === false) {
        console.log(
          'this.getDirectOrdersPaymentEmployees.data',
          this.getDirectOrdersPaymentEmployees.data,
        )
        this.rows = this.customTab(this.getDirectOrdersPaymentEmployees.data)
      }
    },

    totalAmountEmployeesWalletsPayload: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue)
        keys.forEach(key => {
          if (newValue[key] == null) {
            newValue[key] = ''
          }
        })
      },
      deep: true,
    },

    payloadFilter: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue)
        keys.forEach(key => {
          if (newValue[key] == null) {
            newValue[key] = ''
          }
        })
      },
      deep: true,
    },

    getDirectOrdersPaymentEmployees(newValue, oldValue) {
      if (oldValue.length !== 0 && oldValue.length !== newValue.length) {
        this.rows = this.customTab(this.getDirectOrdersPaymentEmployees.data)
      }
    },

    isEmployeesWithoutPaginationLoading(val) {
      if (val === false) {
        this.getEmployeesWithoutPagination.forEach(element => {
          this.employeeOptions.push({
            id: element.id,
            name: element.full_name,
          })
        })
      }
    },
  },

  created() {
    this.getDirectOrdersPaymentEmployees.data != null
      ? (this.rows = this.customTab(this.getDirectOrdersPaymentEmployees.data))
      : null
  },

  mounted() {
    if (this.getEmployeesWithoutPagination.length != 0) {
      this.getEmployeesWithoutPagination.forEach(element => {
        this.employeeOptions.push({
          id: element.id,
          name: element.full_name,
        })
      })
    }
  },
  methods: {
    ...mapActions('orders', [
      'getDirectOrdersSalaryEmployeesAction',
      'loadMoreDirectOrderPaymentEmployeesAction',
      'filterDirectOrdersSalaryEmployeesAction',
      'totalBulkAction',
    ]),

    applyGetDirectOrdersSalaryPayments() {
      this.$store.commit('SET_IS_PAYMENT_EMPLOYEES_LOADING', true)

      this.getDirectOrdersSalaryEmployeesAction()
        .then(() => {
          this.$store.commit('SET_IS_PAYMENT_EMPLOYEES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_PAYMENT_EMPLOYEES_LOADING', false)
        })
    },

    applyTotalAmountEmployeesWalletsPayloadAction() {
      const allIsNUl = Object.values(this.totalAmountEmployeesWalletsPayload).some(
        el => el === '',
      )
      if (allIsNUl) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Warning',
              icon: 'CheckIcon',
              text: 'Veuillez sélectionner le mois et l\'année.',
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else {
        this.isTotalAmountEmployeesWalletsLoading = true
        this.totalBulkAction(this.totalAmountEmployeesWalletsPayload)
          .then(response => {
            this.isTotalAmountEmployeesWalletsLoading = false
            this.montant_total = response.data
            this.totalAmountEmployeesWalletsPayload.month_salary = ''
            this.totalAmountEmployeesWalletsPayload.year = ''
          })
          .catch(error => {
            console.log(error)
          })
      }
    },

    applyFilterEmployeePayedUnPayedPaymentAction() {
      const allIsNUl = Object.values(this.payloadFilter).every(
        el => el === '',
      )
      if (allIsNUl) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Warning',
              icon: 'CheckIcon',
              text: 'Veuillez sélectionner au moins un champ du filtre.',
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else {
        this.isFilterEmployeePayedUnPayedPayment = true
        this.rows = []
        this.payloadFilter.type = 'EM'
        this.filterDirectOrdersSalaryEmployeesAction(this.payloadFilter)
          .then(response => {
            this.isFilterEmployeePayedUnPayedPayment = false

            this.rows = this.customTab(this.getDirectOrdersPaymentEmployees)
            if (this.payloadFilter.status === false) {
              let sum = 0

              for (const i in this.rows) {
                sum += this.rows[i].employee_salary_amount
              }

              this.totalSalariesAmount = sum
            }
            this.total = this.rows.length
          })
          .catch(error => {
            console.log(error)
            this.isFilterEmployeePayedUnPayedPayment = false
            this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Erreur',
              icon: 'AlertTriangleIcon',
              text: error.response.data.message,
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
          },
        )
          })
      }
    },

    customTab(array) {
      const tab = []
      array.forEach(element => {
        switch (element.employee_received_his_salary) {
          case true:
            element.set_employee_received_his_salary = 'Oui'
            break
          case false:
            element.set_employee_received_his_salary = 'Non'
            break
          default:
            element.set_employee_received_his_salary = null
        }
        tab.push(element)
      })
      return tab
    },

    resolveEmployeeReceivedHisSalary: employee_received_his_salary => (employee_received_his_salary ? 'success' : 'danger'),
    resolveClientPayedEmployeeSalary: status => (status ? 'success' : 'danger'),
    reLoadDataPaymentHistoryDirectOrdersAction() {
      this.isReLoadDataPaymentHistoryDirectOrders = true
      this.$store.commit('SET_IS_PAYMENT_EMPLOYEES_LOADING', true)
      this.rows = []
      this.totalSalariesAmount = 0
      this.getDirectOrdersSalaryEmployeesAction()
        .then(response => {
          this.isReLoadDataPaymentHistoryDirectOrders = false
          this.$store.commit('SET_IS_PAYMENT_EMPLOYEES_LOADING', false)
        })
        .catch(error => {
          this.isReLoadDataPaymentHistoryDirectOrders = false
          this.$store.commit('SET_IS_PAYMENT_EMPLOYEES_LOADING', false)
        })
    },

    applyLoadMoreDirectOrderPaymentHistoryAction(url) {
      this.$store.commit('SET_IS_PAYMENT_EMPLOYEES_LOADING', true)
      this.rows = []
      this.loadMoreDirectOrderPaymentEmployeesAction(url)
        .then(() => {
          this.$store.commit('SET_IS_PAYMENT_EMPLOYEES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_PAYMENT_EMPLOYEES_LOADING', false)
        })
    },
  },
}
</script>

<style>
.vgt-select {
  background: transparent !important;
  border-color: #404656 !important;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
